(function ($) {

    $.fn.sticky = function () {

        if ($('.nav-sub-duplicate').length > 0) {
            $('.nav-sub-duplicate').remove();
        }

        var scrollContainer = $(this),
            stickyFirstSelector = '.global-header',
            stickyFirstContainer = $(stickyFirstSelector),
            stickyFirstContainerFixedHeight = 78,
            stickyFirstContainerTopPosition = stickyFirstContainer.offset().top,
            stickySecondSelector = '.nav-sub',
            stickySecondContainerClone = true;

        var stickySecondContainer = $(stickySecondSelector);

        if(stickySecondContainer.length > 0 ) {
            var stickySecondContainerTopPosition = stickySecondContainer.offset().top;

            if (stickySecondContainerClone) {
                var stickySecondContainerCopySelector =  stickySecondSelector + '-duplicate';
                var stickySecondContainerCopy = stickySecondContainer.clone().addClass(stickySecondContainerCopySelector.replace(/\./g, '')).appendTo('body');

                $(stickySecondContainerCopySelector).on('click', 'a[data-target*="#"]', function (event) {
                    event.preventDefault();
                    var target = $(this).attr('data-target') || '';
                    scrollToTarget(target);
                    updateSubMenu(target);
                });
            }
        }


        scrollContainer.on('scroll', function(){
            if(scrollContainer.scrollTop() > 200 ) {
                stickyFirstContainer.addClass('is-fixed');
            } else {
                stickyFirstContainer.removeClass('is-fixed');
            }

            if(stickySecondContainer.length > 0 ) {
                //console.log(stickySecondContainer);
                //console.log(scrollContainer.scrollTop() + ' + ' + stickyFirstContainerFixedHeight + ' > ' + stickySecondContainerTopPosition);
                if((scrollContainer.scrollTop() + stickyFirstContainerFixedHeight) > stickySecondContainerTopPosition) {
                    stickySecondContainer.addClass('is-fixed');
                    if (stickySecondContainerClone) {
                        stickySecondContainerCopy.addClass('is-fixed');
                    }
                } else {
                    stickySecondContainer.removeClass('is-fixed');
                    if (stickySecondContainerClone) {
                        stickySecondContainerCopy.removeClass('is-fixed');
                    }
                }
            }
        });


        /*
        var headerFixedHeight = 78,
            scrollContainer = $('main'),
            header = $('.global-header'),
            headerTopPosition = header.offset().top,
            secondaryNav = $('.nav-sub');
        */
        /*
        if(secondaryNav.length > 0 ) {
            var secondaryNavTopPosition = secondaryNav.offset().top,
                secondaryNavCopy = secondaryNav.clone().addClass('nav-sub-duplicate').appendTo('body');
        }
        scrollContainer.on('scroll', function(){
            if(scrollContainer.scrollTop() > 200 ) {
                header.addClass('is-fixed');
            } else {
                header.removeClass('is-fixed');
            }

            if(secondaryNav.length > 0 ) {
                if((scrollContainer.scrollTop() + headerFixedHeight) > secondaryNavTopPosition ) {
                    secondaryNav.addClass('is-fixed');
                        //.css({'top': (scrollContainer.scrollTop() + headerFixedHeight) + 'px'});
                    secondaryNavCopy.addClass('is-fixed');
                    //*
                    setTimeout(function() {
                        secondaryNav.addClass('animate-children');
                        $('#cd-logo').addClass('slide-in');
                        $('.cd-btn').addClass('slide-in');
                    }, 50);
                    * //
                } else {
                    secondaryNav.removeClass('is-fixed');
                    secondaryNavCopy.removeClass('is-fixed');
                    /*
                    setTimeout(function() {
                        secondaryNav.removeClass('animate-children');
                        $('#cd-logo').removeClass('slide-in');
                        $('.cd-btn').removeClass('slide-in');
                    }, 50);
                    * /
                }
            }
        });
*/

    };
}( jQuery ));
