
var headerFixedHeight = 78,
    subNaviFixedHeight = 80,
    executeOnScroll = false;

function scrollToTarget(target) {
    if (typeof target !== 'undefined') {
        var stickyHeight = headerFixedHeight + subNaviFixedHeight;
        var position = Math.abs($('.main-content').offset().top) + $(target).offset().top - stickyHeight;
        console.log('Position: ' + position);
        $('main').animate({
            scrollTop: position
        }, 500);
    }

    /*
    $(target).velocity('scroll', {
        duration: 500,
        offset: 0,
        easing: 'ease-in-out',
        container: $('main') //main Container has the scrollbars
    });
    */
}

function onScroll(event){
    if (executeOnScroll) {
        var stickyHeight = headerFixedHeight + subNaviFixedHeight;
        var scrollPos = Math.abs($('.main-content').offset().top) + stickyHeight + 2;
        $('.nav-sub-list a').each(function () {
            var currLink = $(this);
            var target = currLink.attr('data-target');
            var refElement = $(target);
            if (refElement.length > 0) {
                if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                    //$('.nav-sub-list li a').removeClass("active");
                    //currLink.addClass("active");
                    updateSubMenu(target);
                } else {
                    //currLink.removeClass("active");
                }
            }
        });
    }
}

function updateMainMenu(url) {
    $('.nav-main-list a').removeClass('active');
    $('.nav-main-list a[href="' + url + '"]').addClass('active');
}
function updateSubMenu(target) {
    //console.log(target);
    $('.nav-sub-list a').removeClass('active');
    $('.nav-sub-list a[data-target="' + target + '"]').addClass('active');
}



var App = function($) {
    function initMainContent() {

        var content = $('body');

        content.find('[data-target*="#"]').on('click', function (event) {
            event.preventDefault();
            var target = $(this).attr('data-target') || '';
            scrollToTarget(target);
            updateSubMenu(target);
        });

        // *only* if we have anchor on the url
        if (window.location.hash) {
            content.find('a[data-target*="' + window.location.hash + '"]').trigger('click');
            //scrollToTarget(window.location.hash);
        }
        executeOnScroll = true;

        $('main').on("scroll", onScroll);

        content.find('main').sticky();
        content.find('.parallax').parallax();
        content.find('.owl-carousel').owlCarousel({
            loop: 1,
            items: 1,
            autoplay: 1,
            autoplayTimeout: 4000,
            smartSpeed: 2000,
            nav: 1,
            navText: ['<i class="icon-arrow-left2"></i>', '<i class="icon-arrow-right2"></i>']
        });


        // Default options
        var lightGalleryOptions = {
            selector: '.image',
            download: false,
            fullScreen: false,
            thumbnail: false,
            hash: false
        };
        var $lightGallery = content.find('[class|=gallery]').lightGallery(lightGalleryOptions);
        var $alert = 1;
        // Default options
        var filterizrOptions = {
            animationDuration: 0.5, //in seconds
            filter: 'all', //Initial filter
            layout: 'sameSize'
        };

        if (content.find('.filtr-container').length > 0) {
            var $filterizr = content.find('.filtr-container').filterizr(filterizrOptions);
            $filterizr.on('filteringEnd', function() {
                $lightGallery.data('lightGallery').destroy(true);
                var $filter = $('.filters .filtr-active').attr('data-filter');
                if ($filter == 'all') {
                    lightGalleryOptions.selector = '.image';
                } else {
                    lightGalleryOptions.selector = '[data-category=' + $filter + ']';
                }
                $lightGallery = content.find('[class|=gallery]').lightGallery(lightGalleryOptions);
            });

        }

        $('.filters .filtr').click(function() {
            $('.filters .filtr').removeClass('filtr-active');
            $(this).addClass('filtr-active');
        });


        // submit form
        content.find('.ajax-form form').submit(function() {
            var $this = $(this);
            var target = '#' + $this.closest('section').attr('id');
            var id = $this.attr('id');
            console.log(id);
            $this.ajaxSubmit({
                clearForm: true,
                success: function(responseText) {
                    //console.log(responseText);
                    $('#' + id).replaceWith($('#' + id, responseText));
                    //$('input[type=submit]', this).removeClass('loading').removeAttr('disabled');
                    if ($(target).length > 0) {
                        scrollToTarget(target);
                        initMainContent();
                    }
                }
            });

            //$('input[type=submit]', this).attr('disabled','disabled').addClass('loading');
            return false;
        });

    }

    $(document).ready(function() {
        // to top right away
        if ( window.location.hash ) {
            scroll(0,0);
        }
        // void some browsers issue
        setTimeout( function() {
            scroll(0,0); }, 1
        );
/*
        $(function() {
            // *only* if we have anchor on the url
            if(window.location.hash) {
                //scrollToTarget(window.location.hash);

            }
        });
*/
        initMainContent();

        /*
         * Replace all SVG images with inline SVG
         */
        $('img.js-svg').each(function(){
            var $img = jQuery(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            jQuery.get(imgURL, function(data) {
                // Get the SVG tag, ignore the rest
                var $svg = jQuery(data).find('svg');

                // Add replaced image's ID to the new SVG
                if(typeof imgID !== 'undefined') {
                    $svg = $svg.attr('id', imgID);
                }
                // Add replaced image's classes to the new SVG
                if(typeof imgClass !== 'undefined') {
                    $svg = $svg.attr('class', imgClass+' replaced-svg');
                }

                // Remove any invalid XML tags as per http://validator.w3.org
                $svg = $svg.removeAttr('xmlns:a');

                // Replace image with new SVG
                $img.replaceWith($svg);

            }, 'xml');

        });

        var isLateralNavAnimating = false;
        //open/close lateral navigation
        $('.nav-main-trigger').on('click', function(event){
            event.preventDefault();
            //stop if nav animation is running
            if( !isLateralNavAnimating ) {
                if($(this).parents('.csstransitions').length > 0 ) isLateralNavAnimating = true;

                $('body').toggleClass('navigation-is-open').addClass('navigation-is-animate');
                $('.nav-main-wrapper').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
                    //animation is over
                    isLateralNavAnimating = false;

                    $('body').removeClass('navigation-is-animate');
                });
            }
        });
    });

    return {
        init: function() {
            initMainContent();
            //setInterval(initMainContent, 100);
        }
    }
}(jQuery);
